import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button, Typography, TextField, Checkbox, FormControlLabel, IconButton, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from "react-google-recaptcha";

const timeRateLimiting = 10000;

export const FormOuvidoria = () => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        assunto: '',
        mensagem: '',
        anonimo: false,
        nome: '',
        email: '',
        telefone: ''
    });

    const recaptchaRef = useRef(null);

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        navigate('/'); // Volta para a página inicial ao fechar
    };

    // Função para verificar se o consentimento de cookie foi dado
    const hasCookieConsent = () => {
        return document.cookie.split('; ').some(item => item.trim().startsWith('pattrolDefaultCookie='));
    };

    // Verifica o consentimento de cookies ao montar o componente
    useEffect(() => {
        if (!hasCookieConsent()) {
            alert("Você precisa aceitar os cookies para acessar este formulário.");
            navigate('/'); // Redireciona para a página inicial ou outra página apropriada
        } else {
            handleOpen(); // Abre o modal se o consentimento for dado
        }
    }, [navigate]);

    // Função para codificar entidades HTML
    const escapeHtml = (text) => {
        return text.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            .replace(/`/g, "&#96;");
    };

    // Função para sanitizar os inputs
    const sanitizeInput = (data) => {
        let sanitizedData = {};
        // Lista de palavras-chave SQL para serem removidas
        const sqlKeywords = ['SELECT', 'INSERT', 'DELETE', 'UPDATE', 'DROP', 'ALTER', 'OR', 'AND', '--', '#', '/*', '*/'];

        for (let key in data) {
            if (typeof data[key] === 'string') {
                let sanitizedValue = data[key]
                    .replace(/<[^>]*>?/gm, '') // Remove tags HTML
                    .replace(/['";$&|<>%{}[\]\\/]/g, '') // Remove caracteres perigosos
                    .replace(/\.\.\//g, '') // Remove Path Traversal
                    .replace(/\0/g, '') // Remove Null Byte
                    .replace(/`/g, "&#96;"); // Codifica backticks

                // Decodifica Unicode e URLs
                sanitizedValue = decodeURIComponent(sanitizedValue);

                const escapeRegex = (string) => {
                    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapa todos os caracteres especiais
                };

                // Bloqueia palavras-chave SQL
                sqlKeywords.forEach(keyword => {
                    if (keyword && keyword.trim()) {
                        const escapedKeyword = escapeRegex(keyword); // Escapa caracteres especiais
                        const regex = new RegExp(`\\b${escapedKeyword}\\b`, 'gi'); // Cria a regex
                        sanitizedValue = sanitizedValue.replace(regex, ''); // Remove a palavra-chave
                    }
                });

                // Codifica entidades HTML
                sanitizedData[key] = escapeHtml(sanitizedValue);
            } else {
                sanitizedData[key] = data[key];
            }
        }
        return sanitizedData;
    };

    // Função para manipular a mudança dos inputs
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    // Função chamada quando o reCAPTCHA é resolvido
    const onRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    // Função para validar os campos obrigatórios
    const isFormValid = () => {
        if (!formData.assunto) {
            alert("O campo 'Assunto' é obrigatório.");
            return false;
        }
        if (!formData.mensagem) {
            alert("O campo 'Mensagem' é obrigatório.");
            return false;
        }
        if (!formData.anonimo) {
            if (!formData.nome) {
                alert("O campo 'Nome' é obrigatório.");
                return false;
            }
            if (!formData.email) {
                alert("O campo 'Email' é obrigatório.");
                return false;
            }
            if (!formData.telefone) {
                alert("O campo 'Telefone' é obrigatório.");
                return false;
            }
        }
        if (!recaptchaValue) {
            alert("Por favor, valide o reCAPTCHA.");
            return false;
        }
        return true;
    };

    // Função para buscar o token Anti-Forgery do cookie
    const getAntiForgeryToken = () => {
        const match = document.cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'));
        return match ? match[2] : null;
    };

    // Função para enviar os dados para a API
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Impede múltiplas submissões
        if (isSubmitDisabled) {
            return;
        }

        // Valida os campos obrigatórios antes de prosseguir
        if (!isFormValid()) {
            return;
        }

        const sanitizedFormData = sanitizeInput(formData);  // Aplica a sanitização a cada campo
        const dataStr = JSON.stringify(sanitizedFormData);  // Cria o JSON formatado

        //console.log("Sanitized Form Data:", sanitizedFormData);
        //console.log("reCAPTCHA Value:", recaptchaValue);

        setIsLoading(true);

        //console.log(dataStr)

        try {
            //console.log("Enviando dados para a API...");
            const response = await fetch('https://www.pattrol.com.br/pattrol-api-website', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "captchaToken": recaptchaValue,  // Passa o token do reCAPTCHA no cabeçalho
                    "X-XSRF-TOKEN": getAntiForgeryToken()  // Adiciona o token Anti-Forgery no cabeçalho
                },
                body: dataStr
            });

            // Antes de enviar os dados para a API
            //console.log("Sanitized Form Data:", sanitizedFormData);

            //console.log("Resposta da API recebida:", response);

            if (response.ok) {
                //const responseData = await response.json();
                //console.log("Sucesso:", responseData);
                alert("Formulário enviado com sucesso!");
                // Armazena o tempo do envio no localStorage
                const timestamp = new Date().getTime();
                localStorage.setItem("lastSubmitTime", timestamp);

                // Desabilita o botão de envio pelo tempo definido em timeRateLimiting
                setSubmitDisabled(true);
                setTimeout(() => setSubmitDisabled(false), timeRateLimiting); // Reabilita após tempo do Rate Limiting
                handleClose();
            } else {
                const errorText = await response.text();  // Captura a mensagem de erro enviada pelo backend
                //console.error("Erro ao enviar o formulário:", errorText);
                //console.error("Erro ao enviar o formulário:", response.status, response.statusText);
                alert("Falha ao enviar o formulário. Verifique o e-mail e o CAPTCHA e tente novamente.");
                recaptchaRef.current.reset(); // Resetar o reCAPTCHA
                setRecaptchaValue(null); // Limpar valor do CAPTCHA
            }
        } catch (error) {
            //console.error("Erro de rede ou outra falha:", error);
            alert("Ocorreu um erro ao enviar o formulário.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleOpen();

        const lastSubmitTime = localStorage.getItem("lastSubmitTime");
        if (lastSubmitTime) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - lastSubmitTime;

            if (timeElapsed < timeRateLimiting) {
                setSubmitDisabled(true);  // Desabilita se ainda não passou o tempo de timeRateLimiting
                const remainingTime = timeRateLimiting - timeElapsed;
                setTimeout(() => setSubmitDisabled(false), remainingTime);
            }
        }
    }, []);

    return (
        <div>
            {/* Modal com o formulário */}
            <Modal
                open={open}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {  // Impede fechar ao clicar fora
                        handleClose();
                    }
                }}
                aria-labelledby="modal-ouvidoria-title"
                aria-describedby="modal-ouvidoria-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                        borderRadius: '10px',
                        maxHeight: '95vh',
                        overflowY: 'auto'
                    }}
                >
                    <IconButton
                        onClick={handleClose} // Botão "X" para fechar o modal
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-ouvidoria-title" variant="h6" component="h2">
                        Enviar Denúncia
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            className="large-textfield"
                            label="Assunto"
                            name="assunto"
                            value={formData.assunto}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            inputProps={{ maxLength: 500 }}
                            helperText="Máximo de 500 caracteres"
                            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                            FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                        />
                        <TextField
                            className="large-textfield"
                            label="Mensagem"
                            name="mensagem"
                            value={formData.mensagem}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            required
                            inputProps={{ style: { fontSize: '1.2rem' }, maxLength: 5000 }}
                            helperText="Máximo de 5000 caracteres"
                            InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                            FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.anonimo}
                                    onChange={handleChange}
                                    name="anonimo"
                                />
                            }
                            label="Enviar de forma anônima"
                        />
                        {!formData.anonimo && (
                            <>
                                <TextField
                                    className="large-textfield"
                                    label="Nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                    inputProps={{ maxLength: 200 }}
                                    helperText="Máximo de 200 caracteres"
                                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                                    FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                                />
                                <TextField
                                    className="large-textfield"
                                    label="E-mail"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                    inputProps={{ maxLength: 200 }}
                                    helperText="Máximo de 200 caracteres"
                                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                                    FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                                    type="email"
                                />
                                <TextField
                                    className="large-textfield"
                                    label="Telefone"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                    inputProps={{ maxLength: 200 }}
                                    helperText="Máximo de 200 caracteres"
                                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                                    FormHelperTextProps={{ style: { fontSize: '1rem' } }}
                                />
                            </>
                        )}

                        {/* Adiciona o componente ReCAPTCHA */}
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Chave de site do reCAPTCHA
                            onChange={onRecaptchaChange}
                        />

                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={isSubmitDisabled || isLoading}>
                            Enviar
                        </Button>
                    </form>
                    {/* Adiciona a sobreposição de carregamento */}
                    {isLoading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 10
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Modal>
        </div>
    );
};
