import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/Navigation";
import { Header } from "./components/Header";
import { Manifesto } from "./components/Manifesto";
import { Proposito } from "./components/Proposito";
import { Valores } from "./components/Valores";
import { Servicos } from "./components/Servicos";
import { Contato } from "./components/Contato";
import { FormOuvidoria } from './components/Ouvidoria/FormOuvidoria';

import "./App.css";

function App() {
  return (
    <Router>
      <div align="center" justify-content="center">
        <CookieConsent
          location="bottom"
          buttonText="Aceito!"
          cookieName="pattrolDefaultCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Este site utiliza apenas cookies básicos para melhorar a experiência de uso e navegação, além de coletar o IP por questões de segurança.
          <br />
          <br />
          Não há coleta de dados pessoais dos visitantes, nem coleta de dados para fins de marketing.
          <br />
          <br />
          Ao clicar "Aceito" você declara estar de acordo com a política de navegação deste site.
        </CookieConsent>

        <Navigation />
        <Header />

        {/* Rotas */}
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Manifesto />
                <Proposito />
                <Valores />
                <Servicos />
                <Contato />
              </>
            } 
          />
          <Route path="/ouvidoria" element={<FormOuvidoria />} /> {/* Rota para a ouvidoria */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;